import '@polymer/iron-icon';
import '@polymer/paper-button';
import { html, PolymerElement } from '@polymer/polymer';
import { ReduxMixin } from '../mixins/redux-mixin.js';
import { uiActions } from '../redux/actions.js';
import './hoverboard-icons.js';
import './shared-animations.js';

class AboutBlock extends ReduxMixin(PolymerElement) {
  static get template() {
    return html`
    <style include="shared-styles flex flex-alignment flex-reverse">
      :host {
        display: block;
      }

      .container {
        padding-top: 64px;
      }

      .content {
        display: grid;
        grid-gap: 32px;
        grid-template-columns: 1fr;
      }

      .statistics-block {
        width: 100%;
        display: grid;
        grid-gap: 32px 16px;
        grid-template-columns: repeat(2, 1fr);
      }

      .numbers {
        font-size: 40px;
      }

      .numbers::after {
        content: "";
        display: block;
        height: 2px;
        width: 64px;
        background-color: var(--default-primary-color);
      }

      .label {
        margin-top: 4px;
      }

      @media (min-width: 640px) {
        .content {
          grid-gap: 64px;
          grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
        }

        .statistics-block {
          grid-gap: 32px;
        }

        .numbers {
          font-size: 56px;
        }
      }

    </style>

    <div class="container">

      <div class="content">

        <div>
          <h1 class="container-title">{$ aboutBlock.title $}</h1>
          <p>The natural world is deteriorating at rates unparalleled in human history. Deforestation and Land-Use Change are driving 25% of global emissions. <br /><br />Major conservation and restoration efforts are underway to mitigate and safeguard against these losses, and to highlight the urgency of the issue, 2021-2030 has been declared the “UN Decade on Ecosystem Restoration”. However, we cannot preserve what we cannot measure.<br />There is an urgent need to understand the location, health and ecological value of nature and biodiversity, and ensure these metrics are reflected in policy, finance, and decision-making. Machine learning (ML) can play a significant role in responding to this critical call for action and can accelerate the conservation and sustainable use of our natural world. From tracing pollution and monitor changing coral reefs in our oceans to guarding wildlife species on the brink of extinction with remote sensing. At DS3Lab Climate+AI we believe in impactful ML-based solutions. We focus our research on:<ul><li>Novel ML innovations</li><li>Inclusive multidisciplinary collaborations between domain experts, local communities and Indigenous Peoples, and the ML community</li><li>Ethical considerations surrounding data ownership and privacy</li></ul>,
          </p>
          <a
            href="{$ aboutBlock.callToAction.featuredSessions.link $}"
            ga-on="click"
            ga-event-category="video"
            ga-event-action="watch"
            ga-event-label="about block - {$ aboutBlock.callToAction.featuredSessions.label $}"
            target="_blank"
            rel="noopener noreferrer">
            <paper-button class="animated icon-right">
              <span class="cta-label">{$ aboutBlock.callToAction.featuredSessions.label $}</span>
              <iron-icon icon="hoverboard:arrow-right-circle"></iron-icon>
            </paper-button>
          </a>

        <div class="statistics-block">

          <div class="item">
            <div class="numbers">{$ aboutBlock.statisticsBlock.tracks.number $}</div>
            <div class="label">{$ aboutBlock.statisticsBlock.tracks.label $}</div>
          </div>

          <div class="item">
            <div class="numbers">{$ aboutBlock.statisticsBlock.days.number $}</div>
            <div class="label">{$ aboutBlock.statisticsBlock.days.label $}</div>
          </div>

        </div>
      </div>
    </div>
`;
  }

  static get is() {
    return 'about-block';
  }

  _playVideo() {
    uiActions.toggleVideoDialog({
      title: '{$  aboutBlock.callToAction.howItWas.title $}',
      youtubeId: '{$  aboutBlock.callToAction.howItWas.youtubeId $}',
      disableControls: true,
      opened: true,
    });
  }
}

window.customElements.define(AboutBlock.is, AboutBlock);
